'use client';

import { useCallback, useEffect, useState } from 'react';

import type { Database } from 'schema.gen';
import type { SupabaseClient } from '@supabase/supabase-js';
import { useEvents } from 'components/events/context';
import { usePrevious } from 'hooks/use-previous';
import useReconnectOnTabChange from 'hooks/use-reconnect-on-tab-change';

export default function RealtimeMaintenanceEmitter({
  initial,
}: {
  initial: boolean;
}) {
  const { emitter } = useEvents();

  const [enabled, setEnabled] = useState(initial);
  const previousEnabled = usePrevious(enabled);

  const createChannel = useCallback(
    async (supabase: SupabaseClient<Database>) => {
      return supabase.channel(`maintenance`).on<{ enabled: boolean }>(
        'broadcast',
        {
          event: 'maintenance_mode_updated',
        },
        (event) => {
          setEnabled(event.payload.enabled);
        },
      );
    },
    [],
  );

  const fetchMaintenanceMode = useCallback(async () => {
    const { enabled } = await fetch('/api/maintenance').then((res) =>
      res.json(),
    );

    setEnabled(enabled);
  }, []);

  useEffect(() => {
    if (previousEnabled !== enabled) {
      emitter.emit('maintenance-mode-updated', { enabled });
    }
  }, [enabled, previousEnabled, emitter]);

  useReconnectOnTabChange(createChannel, fetchMaintenanceMode);

  return null;
}
