'use client';

import { Loader2 } from 'lucide-react';
import type { MaintenanceModeUpdatedEvent } from 'components/events/definitions';
import Observe from '../events/observe';
import { toast } from 'components/ui/sonner';
import { useRouter } from 'next/navigation';
import wait from 'waait';

const TIME_TO_RELOAD = 10;

export default function MaintenanceObserver() {
  const router = useRouter();

  const handleMaintenanceModeUpdated = async (
    event: MaintenanceModeUpdatedEvent,
  ) => {
    const start = new Date();
    toast.loading(
      event.enabled
        ? `Entering maintenance mode in ${TIME_TO_RELOAD} seconds...`
        : `Exiting maintenance mode in ${TIME_TO_RELOAD} seconds...`,
      {
        id: 'toast',
        icon: <Loader2 className="size-4 animate-spin text-gold" />,
        dismissible: false,
        closeButton: false,
      },
    );

    setInterval(() => {
      const diff = new Date().getTime() - start.getTime();
      if (diff < TIME_TO_RELOAD * 1_000) {
        toast.loading(
          `${event.enabled ? 'Entering' : 'Exiting'} maintenance mode in ${Math.round(
            TIME_TO_RELOAD - diff / 1000,
          )} seconds...`,
          {
            id: 'toast',
            icon: <Loader2 className="size-4 animate-spin text-gold" />,
            dismissible: false,
            closeButton: false,
          },
        );
      } else {
        toast.dismiss('toast');
      }
    }, 1_000);

    // it can take up to 10 seconds for the update to propagate in the edge servers
    // https://vercel.com/docs/storage/edge-config/edge-config-limits#write-propagation
    await wait(TIME_TO_RELOAD * 1_000);

    toast.dismiss('toast');

    while (true) {
      router.refresh();
      await wait(2_000);
    }
  };

  return <Observe onMaintenanceModeUpdated={handleMaintenanceModeUpdated} />;
}
