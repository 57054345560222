'use client';

import type { Events } from './definitions';
import { useEffect } from 'react';
import { useEvents } from './context';

// Utility type from step 1
type ToPascalCase<S extends string> = S extends `${infer Head}-${infer Tail}`
  ? `${Capitalize<Head>}${ToPascalCase<Tail>}`
  : Capitalize<S>;

// ObserveProps from step 2
type ObserveProps = {
  [K in keyof Events as `on${ToPascalCase<K>}`]?: (event: Events[K]) => void;
};

// pascalToKebabCase function from step 3
function pascalToKebabCase(str: string): string {
  return str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
}

function Observe(props: ObserveProps) {
  const { emitter } = useEvents();

  useEffect(() => {
    for (const key of Object.keys(props) as (keyof ObserveProps)[]) {
      if (typeof props[key] === 'function' && key.startsWith('on')) {
        const eventNamePascal = key.slice(2); // Remove 'on' prefix
        const eventName = pascalToKebabCase(eventNamePascal); // Convert to kebab-case
        // @ts-expect-error This is safe
        emitter.on(eventName as keyof Events, props[key]);
      }
    }
    return () => {
      for (const key of Object.keys(props) as (keyof ObserveProps)[]) {
        if (typeof props[key] === 'function' && key.startsWith('on')) {
          const eventNamePascal = key.slice(2);
          const eventName = pascalToKebabCase(eventNamePascal);
          // @ts-expect-error This is safe
          emitter.off(eventName as keyof Events, props[key]);
        }
      }
    };
  }, [emitter, props]);

  return null;
}

export default Observe;
