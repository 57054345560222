import { useCallback, useEffect, useRef, useState } from 'react';
import {
  type SupabaseClient,
  type RealtimeChannel,
} from '@supabase/supabase-js';
import { useIsFirstRender } from '@mantine/hooks';
import type { Database } from 'schema.gen';
import wait from 'waait';
import { usePrevious } from './use-previous';

type ChannelStatus =
  | 'SUBSCRIBED'
  | 'TIMED_OUT'
  | 'CLOSED'
  | 'CHANNEL_ERROR'
  | null;

const formatDate = () => new Date().toISOString();

export default function useReconnectOnTabChange(
  createChannel: (
    supabase: SupabaseClient<Database>,
  ) => RealtimeChannel | Promise<RealtimeChannel>,
  onReconnect?: () => Promise<void>,
  callReconnectOnFirstConnection = false,
) {
  const channelRef = useRef<RealtimeChannel | null>(null);
  const [connectingState, setConnectingState] = useState<
    'connecting' | 'connected' | 'disconnected'
  >('disconnected');
  const previousConnectingState = usePrevious(connectingState);
  const [channelStatus, setChannelStatus] = useState<ChannelStatus | null>(
    null,
  );
  const previousChannelStatus = usePrevious(channelStatus);
  const hasSuccessfullySubscribed = useRef(false);
  const reconnectAttempts = useRef(0);
  const isFirstRender = useIsFirstRender();
  const maxRetries = 3;

  const connect = useCallback(async () => {
    if (channelStatus === 'SUBSCRIBED' || connectingState === 'connecting') {
      console.log(
        `${formatDate()} 🔄 Connection already in progress or active`,
      );
      return;
    }

    await channelRef.current?.unsubscribe();
    channelRef.current = null;
    setConnectingState('connecting');
    console.log(`${formatDate()} 🔌 Initiating connection...`);

    const { createClient } = await import('utils/supabase/client');
    const supabase = createClient();
    await supabase.auth.getSession();

    const channel = await createChannel(supabase);
    channelRef.current = channel;

    channel.subscribe((status, error) => {
      setChannelStatus(status);

      const statusMessages = {
        SUBSCRIBED: '✅ Channel subscribed successfully',
        CLOSED: '⬇️ Channel closed',
        CHANNEL_ERROR: `❌ Channel error: ${error?.message ?? 'Unknown error'}`,
        TIMED_OUT: '⏱️ Channel timed out',
      };

      console.log(
        `${formatDate()} ${statusMessages[status] || `⚠️ Unknown status: ${status}`}`,
        reconnectAttempts.current > 0
          ? `(Attempt ${reconnectAttempts.current}/${maxRetries})`
          : '',
        channel.subTopic,
      );

      if (status === 'SUBSCRIBED') {
        setConnectingState('connected');
      } else {
        setConnectingState('disconnected');
      }
    });
  }, [channelStatus, createChannel, connectingState]);

  // connect on render
  useEffect(() => {
    if (isFirstRender) {
      void connect();
    }
  }, [isFirstRender, connect]);

  // call onReconnect callback when channel is successfully re-subscribed
  // or when the first connection is made (if callReconnectOnFirstConnection is true)
  useEffect(() => {
    if (
      channelStatus === 'SUBSCRIBED' &&
      previousChannelStatus !== 'SUBSCRIBED'
    ) {
      if (hasSuccessfullySubscribed.current ?? callReconnectOnFirstConnection) {
        void onReconnect?.();
      }
      hasSuccessfullySubscribed.current = true;
    }
  }, [
    channelStatus,
    onReconnect,
    previousChannelStatus,
    callReconnectOnFirstConnection,
  ]);

  // reconnect on tab focus
  useEffect(() => {
    const onTabChange = () => {
      if (!document.hidden) {
        void connect();
      }
    };

    document.addEventListener('visibilitychange', onTabChange);
    return () => document.removeEventListener('visibilitychange', onTabChange);
  }, [connect]);

  useEffect(() => {
    if (
      previousConnectingState !== 'disconnected' &&
      connectingState === 'disconnected'
    ) {
      if (reconnectAttempts.current < maxRetries) {
        console.log(
          `${formatDate()} 🔄 Scheduling reconnection attempt ${reconnectAttempts.current + 1}/${maxRetries}`,
        );
        void wait(1_000).then(connect);
        reconnectAttempts.current += 1;
      } else {
        console.log(`${formatDate()} ❌ Max reconnection attempts reached`);

        window.location.reload();
      }
    } else if (
      previousConnectingState === 'connecting' &&
      connectingState === 'connected'
    ) {
      console.log(`${formatDate()} ✨ Connection established successfully`);
      reconnectAttempts.current = 0;
    }
  }, [connectingState, previousConnectingState, connect]);

  useEffect(() => {
    return () => {
      void channelRef.current?.unsubscribe();
      channelRef.current = null;
    };
  }, []);

  return { status: channelStatus, channel: channelRef.current };
}
