'use client';

import React, { useContext } from 'react';
import mitt, { type Emitter } from 'mitt';
import type { Events } from './definitions';

export const emitter: Emitter<Events> = mitt();

export interface EventContextType {
  emitter: typeof emitter;
}

const EventContext = React.createContext<EventContextType>({ emitter });

export function EventProvider({ children }: { children: React.ReactNode }) {
  return (
    <EventContext.Provider value={{ emitter }}>
      {children}
    </EventContext.Provider>
  );
}

export function useEvents(): EventContextType {
  return useContext(EventContext);
}
