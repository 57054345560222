'use client';

import type { ExternalToast, toast } from 'sonner';

import { Loader } from 'lucide-react';
import type { ReactNode } from 'react';
import dynamic from 'next/dynamic';

const Sonner = dynamic(() => import('sonner').then((mod) => mod.Toaster), {
  ssr: false,
});

type ToasterProps = React.ComponentProps<typeof Sonner>;

const Toaster = ({ ...props }: ToasterProps) => {
  return (
    <Sonner
      className="toaster group"
      theme="dark"
      style={{
        pointerEvents: 'auto',
      }}
      toastOptions={{
        classNames: {
          default: 'bg-secondary text-foreground',
          icon: 'w-fit',
          closeButton:
            'hover:scale-110 transition-transform hover:!bg-transparent',
          toast:
            'group toast group-[.toaster]:bg-white/[3%] group-[.toaster]:text-foreground group-[.toaster]:border-white/15 group-[.toaster]:shadow-lg',
          description: 'group-[.toast]:text-muted-foreground',
          actionButton:
            'group-[.toast]:bg-primary group-[.toast]:text-primary-foreground',
          cancelButton:
            'group-[.toast]:bg-muted group-[.toast]:text-muted-foreground',
        },
      }}
      {...props}
    />
  );
};

function showToast(
  icon: unknown,
  fallback: ReactNode,
  message: ReactNode,
  data?: ExternalToast,
  loop = false,
) {
  void Promise.all([
    import('sonner').then(({ toast }) => toast),
    import('components/lord-icon-player').then(({ PlayOnce }) => PlayOnce),
  ]).then(([toast, PlayOnce]) => {
    toast(message, {
      icon: <PlayOnce icon={icon} fallback={fallback} loop={loop} />,
      ...data,
    });
  });
}

const innerToast = {
  success: (...args: Parameters<typeof toast.success>) => {
    void import('icons/lordicon/check.json').then((CHECK) =>
      showToast(CHECK, '✅', ...args),
    );
  },
  error: (...args: Parameters<typeof toast.error>) => {
    void import('icons/lordicon/error.json').then((ERROR) =>
      showToast(ERROR, '❌', ...args),
    );
  },
  info: (...args: Parameters<typeof toast.info>) => {
    void import('icons/lordicon/info.json').then((INFO) =>
      showToast(INFO, 'ℹ️', ...args),
    );
  },
  warning: (...args: Parameters<typeof toast.warning>) => {
    void import('sonner').then(({ toast }) => {
      toast.warning(...args);
    });
  },
  custom: (...args: Parameters<typeof toast.custom>) => {
    void import('sonner').then(({ toast }) => {
      toast.custom(...args);
    });
  },
  loading: (...args: Parameters<typeof toast.loading>) => {
    void import('icons/lordicon/loading.json').then((LOADING) =>
      showToast(
        LOADING,
        <Loader className="size-4 animate-spin" />,
        ...[...args, true],
      ),
    );
  },
  message: (...args: Parameters<typeof toast.message>) => {
    void import('sonner').then(({ toast }) => {
      toast.message(...args);
    });
  },
  dismiss: (...args: Parameters<typeof toast.dismiss>) => {
    void import('sonner').then(({ toast }) => {
      toast.dismiss(...args);
    });
  },
};
export { Toaster, innerToast as toast };
